<template>
  <div class="device-info" id="watermark">
    <top-bar :title="'设备详情'" :left="true"></top-bar>

    <van-tabs @click="tabsChange" v-model="active" sticky>
      <van-tab name="1" title="设备详情" class="info">
        <van-popup
          v-model="locationDateShow"
          position="bottom"
        >
          <van-datetime-picker
            v-model="dataForm.locationDate"
            type="date"
            title="请选择"
            :formatter="formatDate"
            @cancel="timeCancel"
            @confirm="timeConfirm"
          />
        </van-popup>
        <!-- 弹框 -->
        <van-popup
          v-model="typeShow"
          position="bottom"

        >
          <van-picker
            title="设备类型"
            value-key="label"
            show-toolbar
            :columns="typeList"
            @confirm="typeConfirm"
            @cancel="typeShow = !typeShow"
          />
        </van-popup>
        <van-popup
          v-model="locationShow"
          position="bottom"

        >
          <van-picker
            title="位置"
            value-key="label"
            show-toolbar
            :columns="locationList"
            @confirm="locationConfirm"
            @cancel="locationShow = !locationShow"
          />
        </van-popup>

        <van-popup
          v-model="subareaShow"
          position="bottom"

        >
          <van-picker
            title="小区"
            value-key="label"
            show-toolbar
            :columns="subareaList"
            @confirm="subareaConfirm"
            @cancel="subareaShow = !subareaShow"
          />
        </van-popup>

        <van-popup
          v-model="checkShow"
          position="bottom"

        >
          <van-picker
            title="定期检查"
            value-key="label"
            show-toolbar
            :columns="checkList"
            @confirm="checkConfirm"
            @cancel="checkShow = !checkShow"
          />
        </van-popup>

        <van-cell-group>
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">基本信息</span>
            </template>
          </van-cell>
          <van-field
              required
            clearable
            clear-trigger="always"
            v-model="dataForm.name"
            label="设备名称"
            :readonly="!changeOrDetail"
            placeholder="请输入"
            input-align="right"
          />
          <van-field
            clearable
            clear-trigger="always"
            v-model="dataForm.coding"
            label="物理编号"
            :readonly="!changeOrDetail"
            placeholder="请输入"
            input-align="right"
          />
          <van-cell
              required
            title="设备类型"
            :is-link="changeOrDetail"
            :value-class="{'value-common':!dataForm.typeStr}"
            :value="dataForm.typeStr || '请选择'"
            @click="typeShow = changeOrDetail"
          />
          <van-cell
            title="位置"
            :is-link="changeOrDetail"
            :value="dataForm.location == 1 ? '地上' : '地下'"
            @click="locationShow = changeOrDetail"
          />
          <van-cell
              required
            title="所属小区"
            :is-link="changeOrDetail"
            :value-class="{'value-common':!dataForm.subareaStr}"
            :value="dataForm.subareaStr || '请选择'"
            @click="subareaShow = changeOrDetail"
          />
          <van-field
              required
            clearable
            clear-trigger="always"
            v-model="dataForm.address"
            label="详细地点"
            :readonly="!changeOrDetail"
            placeholder="请输入"
            input-align="right"
          />
          <van-cell
              required
            title="安装日期"
            :is-link="changeOrDetail"
            :value-class="{'value-common':!locationDateStr}"
            :value="locationDateStr || '请选择'"
            @click="locationDateShow = changeOrDetail"
          />
        </van-cell-group>

        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">附属信息</span>
              <div style="color:#666666;float:right;right: 0;" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div>
            </template>
          </van-cell>
          <div v-show="infoVisible">
            <van-field
                required
              clearable
              clear-trigger="always"
              v-model="dataForm.charger"
              label="负责人"
              :readonly="!changeOrDetail"
              placeholder="请输入"
              input-align="right"
            />
            <van-field
              clearable
              required
              clear-trigger="always"
              v-model="dataForm.mobile"
              label="负责人联系电话"
              :readonly="!changeOrDetail"
              placeholder="请输入"
              input-align="right"
            />
            <van-cell
                required
              title="是否需要定期检查"
              :is-link="changeOrDetail"
              :value="dataForm.needCheck == 1 ? '是' : '否'"
              @click="checkShow = changeOrDetail"
            />
            <van-field
              clearable
              clear-trigger="always"
              v-if="dataForm.needCheck == 1"
              v-model="dataForm.checkCycle"
              label="检查周期(天)"
              :readonly="!changeOrDetail"
              placeholder="请输入"
              input-align="right"
              type="number"
            />
            <van-cell title="备注" :border="false" class="cellClass" />
            <van-field type="textarea" clearable clear-trigger="always" :readonly="!changeOrDetail" v-model="dataForm.remark" placeholder="请输入" style="text-align: right" rows="1" autosize/>
          </div>
        </van-cell-group>

        <van-row v-if="changeOrDetail" class="btns">
          <van-col :span="24">
            <van-button type="info" size="large" @click="submit">完成</van-button>
          </van-col>
        </van-row>
        <van-row v-else class="btns" gutter="10">
          <van-col :span="12">
            <van-button plain type="info" size="large" @click="delt" style="background-color:transparent;color: #4581F8;border-color: #4581F8;">删除</van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" @click="changeOrDetail = !changeOrDetail" style="background-color: #4581F8;">编辑</van-button>
          </van-col>
        </van-row>
      </van-tab>

      <van-tab name="2" v-if="this.dataForm.needCheck == 1" title="检查记录" class="list-info">
        <van-popup
          v-model="checkResultShow"
          position="bottom"

        >
          <van-picker
            title="检查结果"
            value-key="label"
            show-toolbar
            :columns="checkResultList"
            @confirm="checkResultConfirm"
            @cancel="checkResultCancel"
          />
        </van-popup>
        <van-popup
          v-model="beginDateShow"
          position="bottom"

        >
          <van-datetime-picker
            @confirm="beginDateConfim"
            @cancel="beginDateShow = false"
            type="date"
            title="选择年月日"
            :min-date="new Date(2017, 0, 1)"
            :max-date="new Date()"
          />
        </van-popup>
        <van-popup
          v-model="endDateShow"
          position="bottom"

        >
          <van-datetime-picker
            @confirm="endDateConfim"
            @cancel="endDateShow = false"
            type="date"
            title="选择年月日"
            :min-date="new Date(2017, 0, 1)"
            :max-date="new Date(2025, 10, 1)"
          />
        </van-popup>
        <div class="addBtn" @click="goAdd">
          <img src="@/assets/img/add.png" alt="" />
          <p>新增、添加</p>
        </div>

        <div class="filterBar">
          <van-row>
            <van-col span="12">
              <p
                @click="checkDateBarShow = !checkDateBarShow"
                :style="{ color: checkDateBarShow ? '#387FF5':'#666666' }"
              >
                检查日期
                <img :src="require(`@/assets/img/${checkDateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
              </p>
            </van-col>
            <van-col span="12">
              <p
                @click="checkResultShow = !checkResultShow"
                :style="{ color: checkResultShow ? '#387FF5':'#666666' }"
              >
                {{ checkResultStr }}
                <img :src="require(`@/assets/img/${checkResultShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
              </p>
            </van-col>
          </van-row>
        </div>

        <div class="dateBar" v-show="checkDateBarShow">
          <van-row>
            <van-col span="8" @click="beginDateShow = !beginDateShow">
              <span>{{
                dataForm.startCheckTime == ""
                  ? "最早"
                  : dataForm.startCheckTime
              }}</span></van-col
            >
            <van-col span="2"><span>-</span></van-col>
            <van-col span="8" @click="endDateShow = !endDateShow">
              <span>{{
                dataForm.endCheckTime == "" ? "至今" : dataForm.endCheckTime
              }}</span></van-col
            >
            <van-col span="3" @click="dateClose"><span>重置</span></van-col>
            <van-col span="3" @click="changeDate"><span>确定</span></van-col>
          </van-row>
        </div>
        <div class="cont">
          <!-- <van-pull-refresh
            v-model="isloading"
            success-text="刷新成功"
            @refresh="onPullDownRefresh"
          > -->
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getDataList"
              v-if="dataList.length > 0"
              offset="10"
            >
              <div class="totalCount">共有&nbsp;<span>{{totalCount}}</span>&nbsp;条数据</div>
              <div class="list-item" v-for="item in dataList" :key="item.id" @click="selectInfo(item.id)">
                <div class="content">
                  <van-image :src="item.url||require('@/assets/img/default.png')" class="content-img">
                    <template slot="error">
                      <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                    </template>
                    <template slot="loading">
                      <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                    </template>
                  </van-image>
                  <div class="content-text">
                    <div class="top-text">{{ '检查者：' + item.userName }}</div>
                    <div class="bottom-text">{{ '检查日期：' + item.checkTime }}</div>
                  </div>
                  <van-icon name="arrow" color="#666" class="content-icon"/>
                </div>
              </div>
            </van-list>
            <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
          <!-- </van-pull-refresh> -->
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopBar from "@/components/topBar/topBar";
import { formatterDate, formatterDateTimeWithOutSec } from "@/utils/utils";
import { getDictTree, getbelongSubArea } from "@/utils/common";
import { isNumber } from "@/utils/validate";

export default {
  components: { TopBar },
  data() {
    return {
      infoVisible: true,
      totalCount: 0,
      beginDateShow: false,
      endDateShow: false,
      locationDateStr: "",
      locationShow: false,
      loading: false,
      isloading: false,
      finished: false,
      dataList: [],
      activeKey: 0,
      active: 0,
      value: "",
      typeShow: false,
      subareaShow: false,
      checkShow: false,
      checkResultShow: false,
      locationDateShow: false,
      changeOrDetail: false,
      checkDateBarShow: false,
      checkResultStr: "检查结果",
      dataForm: {
        //检查记录
        page: 0,
        limit: 5,
        startCheckTime: "",
        endCheckTime: "",
        checkResult: "",
        deviceId: "",
        searchValue: "",
        //设备详情
        id: "",
        community: "",
        subarea: "",
        subareaStr: "请选择",
        name: "",
        type: "",
        typeStr: "请选择",
        coding: "",
        location: "1",
        address: "",
        locationDate: "",
        charger: "",
        mobile: "",
        needCheck: "0",
        checkCycle: "",
        updateTime: "",
        remark: "",
        lat: "",
        lng: "",
      },
      checkList: [
        { value: "1", label: "是" },
        { value: "0", label: "否" },
      ],
      locationList: [
        { value: "1", label: "地上" },
        { value: "2", label: "地下" },
      ],
      checkResultList: [
        { value: "0", label: "所有" },
        { value: "1", label: "正常" },
        { value: "2", label: "不正常" },
      ],
      subareaList: [],
      typeList: [],
      fileList: [],
    };
  },
  computed: { ...mapState(["deviceId"]) },
  methods: {
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.dataForm.page++;
      this.$http({
        url: this.$http.adornUrl("/wxapp/device/check/list"),
        method: "post",
        params: this.$http.adornParams(this.dataForm, false),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.isloading = false
            if (this.dataList.length == data.page.totalCount) {
              this.$toast.clear();
              this.finished = true;
              return;
            }
            if(this.dataForm.page==1&&data.page.list.length==0){
              this.dataList=[]
            }
            this.dataList = this.dataList.concat(data.page.list);
            this.totalCount = data.page.totalCount;
          }
          //     // 加载状态结束
          this.$toast.clear();
          this.loading = false;
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    timeCancel() {
      this.locationDateShow = false;
    },
    timeConfirm(value) {
      // debugger
      if (value) {
        this.locationDateStr = formatterDate(value);
        this.dataForm.locationDate = formatterDate(value);
      }
      this.locationDateShow = false;
    },
    submit() {
      if (this.dataForm.name === "") {
        return this.$toast.fail("失败:名称不能为空");
      }
      if (this.dataForm.type === "") {
        return this.$toast.fail("失败:设备类型不能为空");
      }
      if (this.dataForm.needCheck == 1) {
        if (this.dataForm.checkCycle === "") {
          return this.$toast.fail("失败:检查周期不能为空");
        }
      }
      if (this.dataForm.subareaStr === "请选择") {
        return this.$toast.fail("失败:所属小区不能为空");
      }
      if (this.dataForm.address === "") {
        return this.$toast.fail("失败:详细地点不能为空");
      }
      if (this.locationDateStr === "") {
        return this.$toast.fail("失败:安装日期不能为空");
      }
      if (this.dataForm.charger === "") {
        return this.$toast.fail("失败:负责人不能为空");
      }
      if (this.dataForm.mobile === "") {
        return this.$toast.fail("失败:负责人联系电话不能为空");
      }
      if (!isNumber(this.dataForm.mobile) && this.dataForm.mobile.indexOf('*') == -1) {
        return this.$toast.fail("失败:请输入有效的负责人联系电话");
      }
      let orgId = this.$orgId;
      if (this.dataForm.subarea) {
        orgId = this.dataForm.subarea;
      }
      this.dataForm["orgId"] = orgId;

      let url = "/wxapp/device/save";
      if (this.dataForm.id && this.dataForm.id != 0) {
        url = "/wxapp/device/update";
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: this.dataForm,
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.clear();
          localStorage.setItem('isRefresh', 1)
          this.$toast.success({message:'新增成功'})
          this.$router.go(-1);
        } else {
          this.$toast.clear();
          this.$toast.fail(data.msg);
        }
      });
    },
    selectInfo(deviceId) {
      this.$router.push({
        path:
          "/device-check-info?id=" + deviceId + "&deviceId=" + this.dataForm.id,
      });
    },
    goAdd() {
      this.$router.push({
        path:
          "/device-check-info?name=" +
          this.dataForm.name +
          "&deviceId=" +
          this.dataForm.id,
      });
    },
    beginDateConfim(value) {
      let d = new Date(this.dataForm.endCheckTime);
      if (
        this.dataForm.startCheckTime != "" &&
        d.getTime(d) < value.getTime(value)
      ) {
        return this.$toast.fail("开始时间不能比结束时间大哦");
      }
      this.dataForm.startCheckTime = formatterDate(value);
      this.beginDateShow = false;
    },
    endDateConfim(value) {
      let d = new Date(this.dataForm.startCheckTime);
      if (
        this.dataForm.endCheckTime != "" &&
        d.getTime(d) > value.getTime(value)
      ) {
        return this.$toast.fail("结束时间不能比开始时间小哦");
      }
      this.dataForm.endCheckTime = formatterDate(value);
      this.endDateShow = false;
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.dataForm.page = 0;
      this.getDataList()
    },
    changeDate() {
      this.onPullDownRefresh()
    },
    dateClose() {
      this.dataForm.startCheckTime = "";
      this.dataForm.endCheckTime = "";
    },
    getDetail() {
      console.log(this.deviceId);
      this.$http({
        url: this.$http.adornUrl("/wxapp/device/info/" + this.dataForm.id),
        method: "post",
      }).then(({ data }) => {
        console.log(data);
        if (data.code == 0) {
          (this.dataForm.id = data.deviceInfo.id || 0),
            (this.dataForm.deviceId = data.deviceInfo.id || 0),
            (this.dataForm.community = data.deviceInfo.community || 0),
            (this.dataForm.subarea = data.deviceInfo.subarea || ""),
            (this.dataForm.subareaStr = data.deviceInfo.subareaStr || "请选择"),
            (this.dataForm.name = data.deviceInfo.name || ""),
            (this.dataForm.type = data.deviceInfo.type || ""),
            (this.dataForm.typeStr = data.deviceInfo.typeStr || "请选择"),
            (this.dataForm.coding = data.deviceInfo.coding || ""),
            (this.dataForm.location = data.deviceInfo.location || "1"),
            (this.dataForm.address = data.deviceInfo.address || ""),
            (this.dataForm.locationDate =
              new Date(data.deviceInfo.locationDate) || ""),
            (this.locationDateStr = data.deviceInfo.locationDate || ""),
            (this.dataForm.charger = data.deviceInfo.charger || ""),
            (this.dataForm.mobile = data.deviceInfo.mobile || ""),
            (this.dataForm.needCheck = data.deviceInfo.needCheck || "0"),
            (this.dataForm.checkCycle = data.deviceInfo.checkCycle || ""),
            (this.dataForm.updateTime = data.deviceInfo.updateTime || ""),
            (this.dataForm.remark = data.deviceInfo.remark || ""),
            (this.dataForm.lat = data.deviceInfo.lat || ""),
            (this.dataForm.lng = data.deviceInfo.lng || ""),
            console.log("data-------------------------");
          console.log(data);

          this.loading = false;
          this.$toast.clear();
        } else {
          this.$toast.clear();
          this.$toast.fail(data.msg);
        }
      });
    },
    tabsChange(e) {
      if (e == "2") {
        this.getDataList();
      }
    },
    typeConfirm(value) {
      this.dataForm.typeStr = value.label;
      this.dataForm.type = value.value;
      this.typeShow = false;
    },
    locationConfirm(value) {
      this.dataForm.location = value.value;
      this.locationShow = false;
    },
    subareaConfirm(value) {
      this.dataForm.subareaStr = value.label;
      this.dataForm.subarea = value.value;
      this.subareaShow = false;
    },
    checkConfirm(value) {
      this.dataForm.needCheck = value.value;
      this.checkShow = false;
    },
    checkResultConfirm(value) {
      //debugger
      if (value.value && value.value == "0") {
        this.dataForm.checkResult = "";
        this.checkResultStr = "检查结果";
      } else {
        this.dataForm.checkResult = value.value;
        this.checkResultStr = value.label;
      }
      this.dataForm.page=0
      this.dataList = []
        this.getDataList();

      this.checkResultShow = false;
    },
    checkResultCancel() {
      this.dataForm.checkResult = ''
      this.checkResultStr = "检查结果"
      this.checkResultShow = false
      this.onPullDownRefresh()
    },
    // 删除数据
    delt() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/device/delete"),
        method: "post",
        params: this.$http.adornParams({
          id: this.dataForm.id,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            localStorage.setItem('isRefresh',1)
            this.$router.go(-1);
          }
        },
        (err) => {
          this.$toast.error({ message: "删除失败" });
        }
      );
    },
  },

  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    document.querySelector("html").style.backgroundColor = "#fff";
    if (this.$route.query.id || this.$route.query.ids) {
      this.dataForm.id = this.$route.query.id || this.$route.query.ids;
      this.getDetail();
    } else {
      this.changeOrDetail = true;
    }
  },
  mounted() {
    let that = this;
    getbelongSubArea(this.$orgId, function (e) {
      that.subareaList = e;
    });
    getDictTree({ code: "deviceType" }, function (e) {
      that.typeList = e;
    });
  },
  beforeDestroy() {
    document.querySelector("html").style.backgroundColor = "";
  },

  name: "device-info",
};
</script>
<style scoped>
.device-info >>> .van-tab--active {
  color: #007AFF;
}
.device-info >>> .van-tabs__line {
  background-color: #007AFF;
}
.filterBar {
  margin-top: 20px;
}
</style>
